import { createApp } from "vue";
import App from "./App.vue";
const app = createApp(App);
import store from "./store/index";
import ElementPlus from 'element-plus';
import 'element-plus/theme-chalk/index.css';
import { ElMessage } from 'element-plus'
import api from "./api/index";
// import en from '@/language/english';
// import zh from '@/language/zh';
// import "./styles/page.scss";
//VueI18n 多语言
// const messages = {en,zh};
// import { createI18n } from 'vue-i18n';
// const i18n = createI18n({
//   legacy: false,  // 设置为 false，启用 composition API 模式
//   messages,
//   locale: navigator.language,//默认语言  获取浏览器语言
//   fallbackWarn: false,
//   silentFallbackWarn: true,
//   missingWarn: false,
//   silentTranslationWarn: true
// })

//默认头像
// app.config.globalProperties.$avatar = "https://cdn.d8gx.com/Ceshi/2022-08-0962f1ffbb60db8.png";
//默认显示
// app.config.globalProperties.$default = "获取中";

// 弹出提醒
app.config.globalProperties.$message = ElMessage;

//socket
// import * as socket from "./api/socket";
// app.config.globalProperties.$SOCKET = socket;

// Axios 请求接口
let $api = new api({});
app.config.globalProperties.$api = $api;

//ROUTER 路由
import router from "./route";
router.beforeEach((to, from, next) => {
  if (to.meta && to.meta.title) {
    document.title = to.meta.title;
  }
  window.scrollTo(0, 0);
  next();
});
// //时间转换
// import dayjs from "dayjs";
// app.config.globalProperties.$filters = {
//   format(value) {
//     if (value) {
//       return dayjs(value).format("YYYY-MM-DD HH:mm:ss");
//     } else {
//       return "";
//     }
//   }
// };

app.use(router);
app.use(store);
app.use(ElementPlus);
// app.use(units);
// app.use(i18n);
app.mount("#app");
