<template> 
<banners></banners>
<div class="index_main">
  <div class="index_main_top">
    <el-form :inline="true" :model="formInline" class="demo-form-inline">
      <el-form-item label="分类">
        <el-select v-model="formInline.fl" placeholder="请选择分类" clearable style="width:190px">
          <el-option v-for="(item,i) in option1" :key="i" :label="item.label" :value="item.value" />
        </el-select>
      </el-form-item>
      <el-form-item label="型号">
        <el-select v-model="formInline.xh" placeholder="请选择型号" clearable style="width:190px">
          <el-option v-for="(item,i) in option2" :key="i" :label="item.label" :value="item.value" />
        </el-select>
      </el-form-item>
    </el-form>
  </div>
  <div class="index_main_center" v-for="item in 2" :key="item">
    <div class="main_center">
      <div class="main_center_image">
        <img src="../assets/index/anli.png" alt="">
      </div>
      <div class="main_center_text">
        <div class="main_center_text_title">Cartouche De limitation De Pression</div>
        <div class="main_center_text_info" v-for="item in 3" :key="item">
          <div class="text_info_left">Catégories：</div>
          <div class="text_info_right">Liebherr：A974 / A974B / R904 / R904C / / R906 ADVANCED / R914 / R914B/ R914C / R916 LC / R922 ENGINE KHD / R922 ENGINE LIEBH....</div>
        </div>
        <div class="main_center_text_bottom">
          <div class="text_bottom_list"  v-for="item in 4" :key="item">
            <div class="bottom_list_left">Numéro de stock：</div>
            <div class="bottom_list_right">9000000</div>
          </div>
        </div>
        <div class="index_main_right">
          <div class="index_main_right_call">联系我们</div>
        </div>
      </div>
      <div class="index_main_right_price">$222</div>
    </div>
  </div>

</div>
<div class="index_bg">
    <img src="../assets/index/index_bg1.png" alt="">
  </div>
  <!-- <div class="index_bg2">
    <img src="../assets/index/index_bg2.png" alt="">
  </div> -->
  <div class="index_message_info">
      <div class="index_message_img">
        <img class="img1" src="../assets/index/imjj.jpg" alt="">
        <img class="img2"  src="../assets/index/imgjjs.jpg" alt="">
      </div>
      <div class="index_message_text">
        <div class="index_message_text_title">
          <span class="index_title_span">Who</span>
          <span>Are</span>
          <span>We?</span>
        </div>
        <div class="index_mt">这是示例这是示例这是示例这是示例这是示例这是示例这是示例</div>
        <div class="index_minfos">这是示例这是示例这是示例这是示例这是示例这是示例这是示例这是示例这是示例这是示例这是示例这是示例这是示例这是示例这是示例这是示例这是示例这是示例这是示例这是示例这是示例这是示例这是示例这是示例这是示例这是示例这是示例这是示例这是示例这是示例这是示例这是示例这是示例这是示例这是示例</div>
        <div class="index_blue_infos">
          <div class="index_blue_infos_line"></div>
          <text class="" >这是示例这是示例这是示例这是示例这是示例这是示例这是示例</text>
        </div>
        <div class="index_minfos_btn">示例按钮</div>
      </div>
  </div>
  <div class="index_bg">
    <img src="../assets/index/index_bg3.png" alt="">
  </div>
  <div class="index_bg2">
    <img src="../assets/index/index_bg4.png" alt="">
  </div>
</template>

<script>
import banners from '../components/banner.vue'
export default {
  name: "index",
  components: {
    banners
  },
  data(){
    return{
      formInline:[{fl:'',xh:''}],
      option1:[
        {value:'1',label:'1'},
        {value:'2',label:'2'},
        {value:'3',label:'3'},
        {value:'4',label:'4'},
        {value:'5',label:'5'},
      ],
      option2:[
        {value:'1',label:'1'},
        {value:'2',label:'2'},
        {value:'3',label:'3'},
        {value:'4',label:'4'},
        {value:'5',label:'5'},
      ]
    }
   
  },
  methods:{
    
  }
}
</script>
<style lang="scss" scoped>
.index_main{
    max-width: 1500px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    .index_main_top{
      width:100%;
      display: flex;
      justify-content: start;
      align-items: center;
    }
    .index_main_center{
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      background: #F3F3F3;
      border-radius: 10px;
      padding: 20px 0 20px 20px;
      box-sizing: border-box;
      margin-top: 15px;
      .main_center{
        width: 100%;
        display: flex;
        justify-content: start;
        align-items: center;
        position: relative;
        .main_center_image{
          width: 240px;
          height: 270px;
          img{
            width: 100%;
            height: 100%;
          }
        }
        .main_center_text{
          width: 70%;
          display: flex;
          justify-content: center;
          align-items: start;
          flex-direction: column;
          margin-left: 40px;
          .main_center_text_title{
            font-size: 20px;
            font-weight: bold;
          }
          .main_center_text_info{
            width: 100%;
            margin-top: 15px;
            display: flex;
            justify-content: center;
            align-items: start;
            .text_info_left{
              color: #9E9E9E;
            }
          }
          .main_center_text_bottom{
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
            // grid-column-gap: 15px;
            grid-row-gap: 15px;
            margin-top: 15px;
            .text_bottom_list{
              width: 50%;
              display: flex;
              justify-content: start;
              align-items: center;
              font-size: 15px;
              .bottom_list_left{
                color: #9E9E9E;
              }
            }
          }
          .index_main_right{
            width: 100%;
              display: flex;
              justify-content: end;
              align-items: center;
              .index_main_right_call{
                width: 130px;
                height: 40px;
                background-color: #FBA31C;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 15px;
                border-radius: 10px;
                cursor: pointer;
              }
            }
        }
        .index_main_right_price{
          position: absolute;
          top: -20px;
          right: 0;
            width: 150px;
            height: 70px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #FBA31C;
            font-size: 35px;
            font-weight: bold;
            border-radius: 0px 10px 0px 10px;
          }
      }
     
    }
    
}
.index_message_info{
    max-width: 1500px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    margin-top: 40px;
    .index_message_img{
      width: 430px;
      height: 440px;
      position: relative;
      .img1{
        width: 300px;
        height: 350px;
        position: absolute;
        left: 0;
        top: 0;
      }
      .img2{
        width: 200px;
        height: 200px;
        position: absolute;
        right: 0;
        bottom: 0;
        z-index: 1;
      }
    }
    .index_message_text{
      margin-left: 40px;
      width: 400px;
      .index_message_text_title{
        width: 100%;
        display: flex;
        justify-content: start;
        align-items: center;
        color: #F6A242;
        font-weight: bold;
        .index_title_span{
          background-color: #0D123A;
          padding: 10px 5px;
        }
        span:nth-child(2){
          margin: 0 10px
        }
      }
      .index_mt{
        font-size: 25px;
        font-weight: bold;
        margin: 20px 0;
        color: #0B133A;
      }
      .index_minfos{
        font-size: 15px;
        color: #A7B4BD;
        overflow:hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 5;
        display: -webkit-box;
        -webkit-box-orient: vertical;
      }
      .index_blue_infos{
        width: 100%;
        display: flex;
        justify-content: start;
        align-items: center;
        overflow:hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 5;
        // display: -webkit-box;
        -webkit-box-orient: vertical;
        color:#0C1B42;
        margin-top: 20px;
        .index_blue_infos_line{
          width: 5px;
          min-height: 40px;
          height: 100%;
          background: #E7A640;
          border-radius: 5px;
          margin-right: 10px;
        }
      }
      .index_minfos_btn{
        width: 120px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #FFF;
        background: #0D123A;
        padding: 10px 20px;
        box-sizing: border-box;
        margin-top: 20px;
        cursor: pointer;
        position: relative;
        &::after{
          position: absolute;
          content: '';
          width: 10px;
          height: 10px;
          background-color:#E7A640;
          right: 0;
          bottom: 0
        }
      }
    }
}
.index_bg{
      max-width: 3000px;
      width: 100%;
      margin-top: 20px;
      img{
        width: 100%;
      }
  }
  .index_bg2{
    max-width: 3000px;
    width: 70%;
    margin: 0 auto;
    margin-top: 20px;
    img{
        width: 100%;
      }
  }
</style>