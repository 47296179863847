<template>
    <div class="footer">
        <div class="footer_top">
            <div class="footer_top_title">这是示例文案头部</div>
            <div class="footer_top_search">
                <input type="text" v-model="email" placeholder="输入你的邮箱"/>
                <div class="search_submit">提交</div>
            </div>
        </div>
        <div class="footer_bottom">
            <div class="footer_bottom_left">
                <div class="bottom_left_top">RENOWKK</div>
                <div class="bottom_left_center">这是实示例文字这是实示例文字这是实示例文字这是实示例文字这是实示例文字</div>
                <div class="bottom_left_bottom">
                    <div class="left_bottom_top">联系方式</div>
                    <div class="left_bottom_bottom">
                        <img src="../assets/footers/footer_icon.png" alt="">
                    </div>
                </div>
            </div>
            <div class="footer_bottom_center">
                <div class="bottom_center_top">Userful Link</div>
                <div class="bottom_center_info" v-for="(item,index) in list_info" :key="index">
                    <div class="dian"></div>
                    {{ item.text }}
                </div>
            </div>
            <div class="footer_bottom_right">
                <div class="bottom_right_top">Contact Information</div>
                <div class="bottom_right_list" >
                    <div class="right_list_icon">
                        <img src="../assets/footers/fiejij.png" alt="">
                    </div>
                    <div class="right_list_text">
                        <text>212321321321@qwe.com</text>
                        <text>212321@qwe.com</text>
                    </div>
                </div>
                <div class="bottom_right_list" >
                    <div class="right_list_icon">
                        <img src="../assets/footers/fdh.png" alt="">
                    </div>
                    <div class="right_list_text">
                        <text>212321321321@qwe.com</text>
                        <text>212321@qwe.com</text>
                    </div>
                </div>
                <div class="bottom_right_list" >
                    <div class="right_list_icon">
                        <img src="../assets/footers/fdwei.png" alt="">
                    </div>
                    <div class="right_list_text">
                        <text>212321321321@qwe.com</text>
                        <text>212321@qwe.com</text>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer_info">
            <div class="footer_info_text">Copyright © 2019. All Rights Reserved. 京ICP备1900dsds00号-1</div>
            <el-backtop :right="20" :bottom="20" />
        </div>
    </div>
</template>
<script>
export default {
  name: "footers",
  components: {
  },
  data(){
    return{
        email:'',
        list_info:[
            {
                text:'About'
            },
            {
                text:'Categroy'
            },
            {
                text:'LatestNews'
            },
            {
                text:'About'
            },
            {
                text:'About'
            },
        ]
    }
   
  },
  methods:{
    
  }
}
</script>
<style lang="scss" scoped>
    .footer{
        max-width: 3000px;
        width: 100%;
        margin: 0 auto;
        // height: 600px;
        display: flex;
        justify-content: start;
        align-items: center;
        flex-direction: column;
        background: url('../assets/footers/footer_bg.png') no-repeat center;
        background-size: 100% 100%;
        margin-top: 30px;
        padding: 30px;
        box-sizing: border-box;
        .footer_top{
            width: 60%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            .footer_top_title{
                font-size: 30px;
                color: #0D123A;
            }
            .footer_top_search{
                width: 600px;
                height: 60px;
                margin-top: 20px;
                background: #FFF;
                display: flex;
                justify-content: space-between;
                align-items: center;
                -webkit-clip-path: polygon(9% 0, 100% 0%, 92% 100%, 0% 100%);
                clip-path: polygon(9% 0, 100% 0%, 92% 100%, 0% 100%);
                input{
                    width: 350px;
                    outline: none;
                    height: 60px;
                    margin-left: 55px;
                    border: none;
                }
                .search_submit{
                    width: 190px;
                    height: 60px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: #0D123A;
                    color: #FFF;
                    -webkit-clip-path: polygon(31% 0, 100% 0, 100% 100%, 0% 100%);
                    clip-path: polygon(31% 0, 100% 0, 100% 100%, 0% 100%);
                    cursor: pointer;
                }
            }
        }
        .footer_bottom{
            width: 60%;
            display: flex;
            justify-content: space-between;
            align-items: start;
            margin-top: 100px;
            .footer_bottom_left{
                width: 25%;
                display: flex;
                justify-content: center;
                align-items: start;
                flex-direction: column;
                .bottom_left_top{
                    font-size: 30px;
                    font-weight: bold;
                    color: #FFF;
                }
                .bottom_left_center{
                    color: #FFF;
                    margin-top: 20px;
                }
                .bottom_left_bottom{
                    display: flex;
                    justify-content: center;
                    flex-direction: column;
                    .left_bottom_top{
                        font-size: 20px;
                        color: #FFF;
                        font-weight: bold;
                        margin: 20px 0;
                    }
                    .left_bottom_bottom{
                        img{
                            width: 30px;
                            height: 30px;
                            border-radius: 50%;
                            overflow: hidden;
                        }
                    }
                }
            }
            .footer_bottom_center{
                width: 15%;
                display: flex;
                justify-content: center;
                align-items: start;
                flex-direction: column;
                .bottom_center_top{
                    color: #FFF;
                    font-size: 20px;
                    font-weight: bold;
                }
                .bottom_center_info{
                    width: 100%;
                    display: flex;
                    justify-content:start;
                    align-items: center;
                    margin-top: 10px;
                    color:#FFF;
                    font-size: 15px;
                    .dian{
                        width: 15px;
                        height: 15px;
                        border-radius: 50%;
                        background-color: #FBA31D;
                        margin-right: 10px;
                    }
                }
            }
            .footer_bottom_right{
                width: 25%;
                display: flex;
                justify-content: center;
                align-items: start;
                flex-direction: column;
                .bottom_right_top{
                    color: #FFF;
                    font-size: 20px;
                    font-weight: bold;
                }
                .bottom_right_list{
                    display: flex;
                    justify-content: start;
                    align-items: center;
                    margin-top: 10px;
                    .right_list_icon{
                        width: 40px;
                        height: 40px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        background-color: #FBA31D;
                        img{
                            width: 15px;
                            height: 15px;
                        }
                    }
                    .right_list_text{
                        display: flex;
                        justify-content: start;
                        align-items: start;
                        flex-direction: column;
                        color: #FFF;
                        font-size: 15px;
                        margin-left: 10px;
                    }
                }
            }
        }
        .footer_info{
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            border-top: 2px solid #262A4F;
            color: #FFF;
            padding-top: 20px;
            margin-top: 20px;
        }
    }
</style>