<template> 
    <div class="swiper">
      <swiper
          :slides-per-view="1"
          :space-between="0"
          @swiper="onSwiper"
          @slideChange="onSlideChange"
          :autoplay="true"
          :loop="true"
          :speed='2000'
          :navigation="true"
        >
          <swiper-slide>
            <video class="sp" autoplay="true" muted  loop="true" src="https://vd2.bdstatic.com/mda-qii74146ya9ik3z0/sc/cae_h264/1726722258393974769/mda-qii74146ya9ik3z0.mp4"></video>
          </swiper-slide>
          <swiper-slide>
            <video class="sp" autoplay="true" muted  loop="true" src="https://media2.hndt.com/data_02/1/3/2024/09/20/d4bf39480b145e14a93f6dbb033f60c3.mp4"></video>
          </swiper-slide>
          <div class="swiper-button-prev" @click="prevEl()">
            <img src="../assets/banner/leftjian.png" alt="">
          </div>
          <div class="swiper-button-next" @click="nextEl()">
            <img src="../assets/banner/rightjian.png" alt="">
          </div>
    </swiper>
    </div>
</template>

<script setup>
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
let useSwiper = null
// 初始化 swiper
const onSwiper = (swiper) => {
    useSwiper = swiper
}
// 滑动事件
const onSlideChange = () => {
    console.log('slide change')
}
const prevEl = () => {
    useSwiper.slidePrev()
}
const nextEl = () => {
    useSwiper.slideNext()
}


</script>
<style lang="scss" scoped>
.swiper{
  max-width: 3000px;
    width: 100%;
    margin: 0 auto;
    position: relative;
    height: 700px;
    .swiper-slide{
      width: 100% !important;
      height: 700px;
      display: flex;
      justify-content: center;
      align-content: center;
      .sp{
        width: 100%;
        height: 100%;
        Object-fit:fill;
      }
    }
    .swiper-wrapper{
       width: 100%;
      height: 400px;
    }
    .swiper-button-prev{
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 45%;
      left: 30px;
      width: 50px;
      height: 50px;
      z-index: 999;
      border-radius: 50%;
      background-color: #FBA31C;
      cursor: pointer;
      img{
        width: 30px;
        height: 30px;
      }
    }
    .swiper-button-next{
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 45%;
      right:30px;
      width: 50px;
      height: 50px;
      z-index: 999;
      border-radius: 50%;
      background-color: #FBA31C;
      cursor: pointer;
      img{
        width: 30px;
        height: 30px;
      }
    }
}
</style>