import { createRouter, createWebHistory } from "vue-router";
const getComponent = (name) => () => import(`../views/${name}.vue`);

let APP_NAME = "海外售卖商品";

const routes = [
  {
    path: "/:pathMatch(.*)",
    redirect: "/",
    hidden: true
  },
  {
    path: "/",
    name: "Index",
    component: getComponent("index"),
    meta: {
      title: APP_NAME + "首页"
    }
  },
  {
    path: "/shop",
    name: "Shop",
    component: getComponent("shop/index"),
    meta: { title: APP_NAME + "新商品" }
  },
  {
    path: "/oldshop",
    name: "oldShop",
    component: getComponent("oldshop/index"),
    meta: { title: APP_NAME + "二手商品" }
  },
  {
    path: "/about",
    name: "about",
    component: getComponent("about/about"),
    meta: { title: APP_NAME + "关于我们" }
  },
  {
    path: "/dtlx",
    name: "dtlx",
    component: getComponent("dtlx/dtlx"),
    meta: { title: APP_NAME + "动态联系" }
  },
];
const router = new createRouter({
  history: createWebHistory(),
  routes
});
export default router;
