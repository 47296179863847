<template>
  <Headers></Headers>
  <router-view></router-view>
  <!-- <Index_home></Index_home> -->
   <Footers></Footers>
</template>

<script>
import Index_home from './views/index.vue'
import Headers from "./components/header"; //顶部菜单
import Footers from "./components/footer";
export default {
  name: 'App',
  components: {
    Index_home,
    Headers,
    Footers
  }
}
</script>

<style>
   ::-webkit-scrollbar {
    display: none;
  }
</style>
