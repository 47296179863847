<template> 
      <div class="index_demo" :class="scrollTop > 170 ? 'fixed_header' : ''">
        <div class="top_nav">
          <div class="top_nav_left">
            <div class="nav_left_phone">
              <img src="../assets/header/dw.png" alt="">
              <view class="nav_left_address">美国华盛顿特区</view>
            </div>
            <div class="nav_left_phone">
              <img src="../assets/header/dh.png" class="icon_phone" alt="">
              <view class="nav_left_address">121212112121212121212</view>
            </div>
            <div class="nav_left_phone">
              <img src="../assets/header/cz.png" class="icon_phone" alt="">
              <view class="nav_left_address">12121212121212</view>
            </div>
          </div>
          <div class="top_nav_right">
            <div class="nav_right_select">
              <img src="../assets/header/mg.png" alt="">
             <el-dropdown>
              <div class="select_text">简体中文</div>
                <template #dropdown>
                  <el-dropdown-menu>
                    <el-dropdown-item  @click="changeLanguage('chinese_traditional')">简体中文</el-dropdown-item>
                    <el-dropdown-item  @click="changeLanguage('english')">英语</el-dropdown-item>
                    <el-dropdown-item  @click="changeLanguage('russian')">俄语</el-dropdown-item>
                  </el-dropdown-menu>
                </template>
              </el-dropdown>
              <!-- <div class="language-item">
                    <a href="javascript:translate.changeLanguage('chinese_traditional');" class="ignore">切换为简体中文</a>
                  </div>
                  <div class="language-item">
                    <a href="javascript:translate.changeLanguage('english');" class="ignore">切换为英语</a>
                  </div>
                  <div class="language-item">
                    <a href="javascript:translate.changeLanguage('russian');" class="ignore">切换为俄语</a>
                  </div> -->
            </div>
            <!-- <div class="nav_right_login">
              <div class="login_btn">登录</div>
              <div class="login_btn">注册</div>
            </div> -->
          </div>
        </div>
        <div class="nav_list">
          <div class="nav_list_left">
            <div class="text">RENOWK</div>
          </div>
          <div class="nav_list_center">
            <div class="nav_lists" v-for="(item,i) in  list" :key="i" :class="active==item.path?'active':''" @click="topath(item)">{{ item.name }}</div>
          </div>
          <div class="nav_list_right">
            <div class="list_right_icon">
              <img src="../assets/header/bird.png" alt="">
            </div>
          </div>
        </div>
      </div>
  </template>
  
  <script>
  export default {
    name: "Header",
    components: {
    },
    data(){
      return{
          active:'Index',
          list:[
          {
              path:'Index',
              name:'首页'
          },
          {
              path:'Shop',
              name:'新产品'
          },
          {
              path:'oldShop',
              name:'二手产品'
          },
          {
              path:'',
              name:'二手设备'
          },
          {
              path:'about',
              name:'关于我们'
          },
          {
              path:'dtlx',
              name:'动态联系'
          }
       ],
      language: 'zh', // 选中的语言
      language_list: ['en',"zh"],
      scrollTop:'',
      }
      
    },
    mounted () {
    	window.addEventListener('scroll', this.handleScroll) // 监听页面滚动
    },
    beforeDestroy () {
      		window.removeEventListener('scroll', this.handleScroll)
    	},
    methods:{
      handleScroll () {
	        this.scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
	        // console.log(this.scrollTop, '滚动距离')
	    },
      changeLanguage(item){
          // window.translate.changeLanguage(item)
          window.translate.changeLanguage(item)
          // setInterval(()=>{
          //   // console.log('触发')
          //   window.translate.execute();
          // },1000)
    },
      topath(item){
        this.active=item.path;
          this.$router.push({ name: item.path });
      },
      selectlange(e){
        this.language=e;
        this.$i18n.locale=e
      }
    }
  }
  </script>
  <style lang="scss">
  .fixed_header{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
  }
    .index_demo{
      max-width: 3000px;
        width: 100%;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        .top_nav{
          width:100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 20px 40px ;
          box-sizing: border-box;
          background-color: #0D123A;
          .top_nav_left{
            display: flex;
            align-items: center;
            grid-column-gap: 15px;
            // grid-row-gap: 20px;
            .nav_left_phone{
              display: flex;
              align-items: center;
              img{
                width: 30px ;
                height: 30px;
                margin-right: 5px;
              }
              .icon_phone{
                width: 25px ;
                height: 25px;
              }
              .nav_left_address{
                color: #fff;;
                max-width: 200px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              }
            }
          }
          .top_nav_right{
            display: flex;
            justify-content: space-between;
            align-items: center;
            .nav_right_select{
              display: flex;
              justify-content: space-between;
              align-items: center;
              cursor: pointer;
              img{
                width: 40px;
                height: 40px;
                border-radius: 50%;
                overflow: hidden;
                margin-right: 10px;
              }
              .select_text{
              color:#fff;
              }
            }
            .nav_right_login{
              display:flex;
              justify-content: space-between;
              align-items: center;
              color: #fff;
              grid-column-gap: 10px;
              margin-left: 20px;
              cursor: pointer;
            }
          }
        }
        .nav_list{
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          background-color: #FFFFFF;
          .nav_list_left{
            width: 300px;
            height: 70px;
            display: flex;
            align-items: center;
            background-color: #FBA31C;
            -webkit-clip-path: polygon(0 0, 74% 0, 100% 100%, 0% 100%);
            clip-path: polygon(0 0, 74% 0, 100% 100%, 0% 100%);
            .text{
              margin-left: 50px;
              font-weight: bold;
              font-size: 25px;
              letter-spacing: 3px;
            }
          }
          .nav_list_center{
            display: flex;
            justify-content: center;
            align-items: center;
            flex: 1;
            .nav_lists{
              width: 70px;
              display: flex;
              justify-content: center;
              margin: 0 20px;
              cursor: pointer;
            }
            .nav_lists:nth-child(1){
              margin: 0;
            }
            .active{
              color:#FBA31D ;
            }
          }
          .nav_list_right{
            width: 300px;
            height: 70px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #FBA31C;
            -webkit-clip-path: polygon(26% 0, 100% 1%, 100% 100%, 0% 100%);
            clip-path: polygon(26% 0, 100% 1%, 100% 100%, 0% 100%);
            .list_right_icon{
              width: 30px;
              height: 30px;
              border-radius: 50%;
              overflow: hidden;
              cursor: pointer;
              img{
                width: 100% ;
                height: 100%;
              }
            }
          }
        }
        .nav{
            font-size:30px;
            margin:0 20px;
        }
    }
  </style>